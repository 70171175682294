import api from "@/base/utils/request";
import download from "@/base/utils/download";

//获取其他设置
export const getConfig = data => {
  return api({
    url: "/admin/cyc/settings/getMessageConfig",
    method: "post",
    data
  });
};
//获取其他设置
export const saveConfig = data => {
  return api({
    url: "/admin/cyc/settings/setMessageConfig",
    method: "post",
    data
  });
};