<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="setting">
    <div class="content" v-loading="loading">
      <div class="title-box">
        <div class="title">消息设置</div>
        <div class="tips">
          <i class="el-icon-warning-outline"></i
          >该设置为客户端中的个人中心消息通知
        </div>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        row-key="value"
        id="sortTable"
        stripe
        :style="{
          width: '50%',
          marginTop: '24px',
        }"
      >
        <!-- 操作 -->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <span
                v-if="th.prop === 'sort'"
                class="el-icon-sort my-handle cursor-pointer"
              ></span>

              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <FixedActionBar>
      <el-button
        v-if="!loading"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onSave('ruleForm')"
        >保存</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
import ImageItem from '@/modules/common/components/ImageItem.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import { getConfig, saveConfig } from '@/modules/message/api/message-config.js'
import Sortable from 'sortablejs/modular/sortable.core.esm.js'

export default {
  data() {
    return {
      loading: false, //加载中
      thead: [
        { label: '拖拽排序', prop: 'sort', minWidth: 80 },
        { label: '功能', prop: 'name', minWidth: 150 },
      ],
      rules: {},
      tbody: [],

      saveLoading: false, //保存中
    }
  },
  methods: {
    //获取list
    getList() {
      this.loading = true
      getConfig()
        .then((res) => {
          const { data } = res
          this.tbody = data.message_list

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    switchChange() {
      this.onSave()
    },
    //保存
    onSave(formName) {
      console.log(this.tbody)
      saveConfig({ config: { message_list: this.tbody } })
        .then((res) => {
          const { msg } = res
          this.$message.success(msg)
          this.getList()
          this.saveLoading = false
        })
        .catch(() => {
          this.saveLoading = false
        })
    },

    //初始化拖拽表格
    initSortable() {
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          '#sortTable .el-table__body>tbody'
        )
        console.log(sortTable)
        this.$sortTable = Sortable.create(sortTable, {
          animation: 150,
          ghostClass: 'sortable-ghost',
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex)
            // 拿到拖拽的item
            const oldItem = this.tbody[event.oldIndex]
            // 删除原本位置
            this.tbody.splice(event.oldIndex, 1)
            // 添加到新的位置
            this.tbody.splice(event.newIndex, 0, oldItem)
            // this.onSave()
            console.log(this.tbody)
          },
        })
        //阻止火狐拖拽新建新页面
        document.body.addEventListener('drop', this.preventDrop, false)
      })
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable) this.$sortTable.destroy()
      document.body.removeEventListener('drop', this.preventDrop, false)
    },
    //阻止默认drop事件
    preventDrop(event) {
      event.preventDefault()
      event.stopPropagation()
    },
  },
  created() {
    this.getList()
  },
  mounted() {
    this.initSortable()
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable()
  },
  components: {
    ImageItem,
    FixedActionBar,
  },
}
</script>

<style lang="scss" scoped>
.title-box {
  display: flex;
  align-items: center;
  .title {
  }

  .tips {
    margin-left: 24px;
    color: #888585;
    i.el-icon-warning-outline {
    }
  }
}
</style>
